/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { RESET_STATE } from '../../constants';

const personaQuerySlice = createSlice({
	name: 'personaQuery',
	initialState: '',
	reducers: {
		setPersonaQuery(state, action) {
			return action.payload;
		},

	},

	extraReducers: (builder) => {
		builder.addCase(RESET_STATE, (state) => '');
	},
});

export const { setPersonaQuery, resetPersonaQuery } = personaQuerySlice.actions;

export default personaQuerySlice.reducer;
