import { Box, Typography } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductIntroCard from '../../components/Cards/ProductIntroCard';
import BrandCard from '../../components/Cards/BrandCard';
import getProductInfo from '../../thunk/productInfoThunk';
import getProductFilter from '../../thunk/productFilterThunk';
import getCodesigners from '../../thunk/coDesignerThunk';
import CodesignerCard from '../../components/Cards/CoDesignerCard';
import MavieURating from '../../components/MavieURating';

function IndividualProductPage() {
	// if path is /client/:id/product/:id and id changes, then we need to fetch the new product
	const { productId } = useParams();
	const query = useSelector((state) => state.filters.query);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getProductInfo(productId));
		dispatch(getProductFilter(productId));
	}, [productId, dispatch]);

	useEffect(
		() => {
			const data = {
				productId,
				query,
			};
			console.log('data', data);
			dispatch(getCodesigners(data, query));
		},
		[productId, query, dispatch],
	);

	return (
		<Box
			sx={{
				width: '100%',
				height: 'auto',
				justifyContent: 'start',
				paddingRight: '16px',
				paddingLeft: '16px',
				gap: '16px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<BrandCard />
			<Typography
				sx={
					{
						color: '#455468',
						fontFamily: 'Inter, sans-serif',
						fontWeight: 600,
						fontSize: '18px',
						textAlign: 'left',
						marginTop: '16px',
						marginBottom: '16px',
					}
				}
			>
				Product
			</Typography>
			<ProductIntroCard />
			{/* <GeneralPersonaCard /> */}
			{/* <PersonaPopup open /> */}
			<CodesignerCard />
			<Outlet />
		</Box>

	);
}

export default IndividualProductPage;
