import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import authAction from '../thunk/authThunk'; // Assuming you have an action creator

function ProtectedRoute() {
	//
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	const location = useLocation();
	const dispatch = useDispatch();
	const [shouldRedirect, setShouldRedirect] = useState(false);

	useEffect(() => {
		//
		if (isLoggedIn == null) {
			dispatch(authAction());
			// if login success, then redirect to the requested page
			// if login fails, then redirect to login page
		}
	}, [dispatch, isLoggedIn, location.pathname]);

	// Redirect unauthenticated users to the login page, retaining the intended destination
	if (isLoggedIn === false) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}
	return <Outlet />;
}

export default ProtectedRoute;
