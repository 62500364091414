import { useState } from 'react';
import PropTypes from 'prop-types';
import SingleCoDesignerIcon from './SingleCoDesignerIcon';
import PersonaPopup from '../../../../Popup/PersonaPopup';

function SingleCoDesignerHead({ coDesignerId }) {
	const [open, setOpen] = useState(false);

	const handlePopupClose = () => {
		setOpen(false);
	};

	const onIconClick = () => {
		setOpen(true);
	};

	return (
		<>
			<SingleCoDesignerIcon onIconClick={onIconClick} coDesignerId={coDesignerId} />
			<PersonaPopup open={open} onClose={handlePopupClose} coDesignerId={coDesignerId} />
		</>
	);
}

export default SingleCoDesignerHead;

SingleCoDesignerHead.propTypes = {
	coDesignerId: PropTypes.string.isRequired,
};
