import {
	Grid, Box, useMediaQuery, Drawer,
} from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductSidebar from './components/ProductSidebar';
import theme from '../../theme';
import getPersona from '../../thunk/personaThunk';
import FloatingButtons from './components/FloatingButton';

function ClientProductPage() {
	const dispatch = useDispatch();
	const ref = useRef();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();
	const { currentProduct } = useSelector((state) => state.filters.product);
	const { userId } = useSelector((state) => state.auth);

	useEffect(
		() => {
			console.log('dispatching getPersona');
			dispatch(getPersona());
		},
		[dispatch],
	);

	const [isSidebarOpen, setIsSidebarOpen] = useState(isMobile);
	const handleSidebarOpen = () => {
		setIsSidebarOpen(true);
	};
	const handleSidebarClose = () => {
		setIsSidebarOpen(false);
	};

	const onScroll = () => {
		ref.current.scrollTop = 0;
	};

	const renderSidebar = () => {
		if (isMobile) {
			return (
				<Drawer
					anchor="left"
					onClose={handleSidebarClose}
					open={isSidebarOpen}
					variant="temporary"
					PaperProps={{
						sx: {
							width: 350,
							top: 0,
							height: '100vh',
						},
					}}
				>
					<ProductSidebar
						onMobileClose={handleSidebarClose}
					/>
				</Drawer>
			);
		}

		return (
			<Grid
				item
				md={3}
				lg={3}
				sx={{
					height: '100vh',
					// overflow: 'auto',
				}}
			>
				<ProductSidebar />
			</Grid>
		);
	};

	useEffect(
		() => {
			if (currentProduct) {
				navigate(`/client/${userId}/product/${currentProduct.id}/metrics`);
			}
		},
		[currentProduct],
	);

	return (
		<Grid
			container
			sx={
				{
					height: '100vh',
					margin: '0px',
					padding: '0px',
					overflow: 'hidden',
				}
			}
		>
			{renderSidebar()}
			<Grid
				item
				xs={12}
				sm={12}
				md={9}
				lg={9}
			>
				<Box
					ref={ref}
					sx={
						{
							width: '100%',
							display: 'flex',
							height: '100vh',
							flexDirection: 'column',
							justifyContent: 'start',
							overflow: 'auto',
							scrollBehavior: 'smooth',
							marginTop: '10px',
							marginBottom: '10px',
							// alignItems: 'center',
						}
					}
				>
					<Outlet />
					{/* {renderFloatingButton()} */}
					{/* {!isMobile && <ScrollToTopButton onClick={onScroll} />} */}
					<FloatingButtons handleMenuClick={handleSidebarOpen} handleScrollToTopClick={onScroll} />
				</Box>
			</Grid>
		</Grid>
	);
}
export default ClientProductPage;
