import { addErrorMessage, popErrorMessage } from '../features/errorMessages/errorMessageSlice';

const errorHandlingMiddleware = ({ dispatch }) => (next) => (action) => {
	// Check if the action is a rejected thunk
	if (action.type.endsWith('/rejected')) {
		console.log('Rejected action:', action); // Add this to debug
		// Extract error message
		const errorMessage = action.payload || 'An unexpected error occurred';

		// Dispatch a global error handling action, for example, to show an error message
		// You need to implement the addErrorMessage action and the corresponding reducer
		dispatch(addErrorMessage(errorMessage));
		setTimeout(() => {
			dispatch(popErrorMessage());
		}, 5000);
	}

	return next(action);
};

export default errorHandlingMiddleware;
