import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPersona as getPersonaAPI } from '../../api';

const getPersona = createAsyncThunk('persona/getPersona', async (_, thunkAPI) => {
	try {
		const response = await getPersonaAPI();
		return response;
	} catch (error) {
		console.error('Error in getPersona thunk:', error);
		return thunkAPI.rejectWithValue(error.message || error);
	}
});

export default getPersona;
