import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useMediaQuery, {
	Dialog, Card, CardHeader, CardContent, IconButton, Skeleton,
} from '@mui/material';

import CloseIcon from '../../icons/CloseIcon';
import PersonaSurveyPlacehoder from '../../../images/PersonaPlacehoder.png';

function PersonaPopup({ coDesignerId, open, onClose }) {
	const [loaded, setLoaded] = useState(false);
	const codesigners = useSelector((state) => state.codesigner.entities);
	const codesigner = codesigners[coDesignerId];
	// This function will be called once the PDF is loaded
	const handleLoad = () => {
		setLoaded(true);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="lg"
		>
			<Card
				elevation={0}
			>
				<CardContent sx={{
					padding: '0px',
				}}
				>
					{!loaded && (
						<Skeleton
							variant="rectangular"
							width="100%"
							height="400px"
						/>
					)}
					<img
						src={codesigner?.report || PersonaSurveyPlacehoder}
						alt="codesigner"
						width="100%"
						onLoad={handleLoad}
						style={{
							overflow: 'auto',
							display: loaded ? 'block' : 'none',
						}}
					/>
				</CardContent>
			</Card>
		</Dialog>
	);
}

PersonaPopup.propTypes = {
	coDesignerId: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default PersonaPopup;
