import axios from 'axios';

const url = () => {
	console.log(process.env.REACT_APP_MODE);
	if (process.env.REACT_APP_MODE === 'LOCAL') {
		return 'http://localhost:3002';
	}
	if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
		return process.env.REACT_APP_API_BASE_URL;
	}
	if (process.env.REACT_APP_MODE === 'PRODUCTION') {
		return process.env.REACT_APP_PRODUCTION_API_BASE_URL;
	}

	return process.env.REACT_APP_API_BASE_URL;
};
const axiosInstance = axios.create({
	baseURL: url(),
	withCredentials: true,
});

axiosInstance.interceptors.response.use(
	(response) => response.data,
	(error) => {
		if (error.response && error.response.status === 404) {
			// if error message contains rating then throw error data not complete
			if (error.response.data.error.includes('rating')) {
				throw new Error('This survey is not completed');
			}
			if (error.response.data.error === 'Product not found') {
				throw new Error('This product information is not available');
			}
			throw new Error('Internet connection is required');
		} else if (error.response) {
			throw new Error(error.response.data.error || 'an error occured, please try again');
		} else if (error.request) {
			throw new Error('cannot connect to the server, please check your internet connection or contact the support team');
		} else {
			throw new Error('an unknown error occured, please contact the support team');
		}
	},
);

export default axiosInstance;
