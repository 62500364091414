/* eslint-disable react/no-array-index-key */
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import ErrorMessage from './ErrorMessage';

function DisplayErrorMessage() {
	const ErrorMessages = useSelector((state) => state.error.messages);

	return (
		<div>
			{ErrorMessages?.map((message, index) => (
				<ErrorMessage
					message={message}
					index={index}
					open
					key={`error-message-${index}`}
				/>
			))}
		</div>
	);
}

export default DisplayErrorMessage;
