import SvgIcon from '@mui/material/SvgIcon';

function WarningIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.9998 7.3125V10.125M8.02614 2.81143L1.84031 13.4984C1.74133 13.6694 1.68913 13.8634 1.68897 14.061C1.6888 14.2586 1.74068 14.4528 1.83938 14.6239C1.93807 14.7951 2.08011 14.9372 2.2512 15.0361C2.42228 15.1349 2.61638 15.187 2.81397 15.187H15.1856C15.3832 15.187 15.5773 15.1349 15.7484 15.0361C15.9195 14.9372 16.0615 14.7951 16.1602 14.6239C16.2589 14.4528 16.3108 14.2586 16.3106 14.061C16.3105 13.8634 16.2583 13.6694 16.1593 13.4984L9.97345 2.81143C9.87463 2.64069 9.73265 2.49895 9.56176 2.40041C9.39087 2.30187 9.19707 2.25 8.9998 2.25C8.80253 2.25 8.60873 2.30187 8.43783 2.40041C8.26694 2.49895 8.12496 2.64069 8.02614 2.81143Z" stroke="#ECA72C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M9 13.5C9.46599 13.5 9.84375 13.1222 9.84375 12.6562C9.84375 12.1903 9.46599 11.8125 9 11.8125C8.53401 11.8125 8.15625 12.1903 8.15625 12.6562C8.15625 13.1222 8.53401 13.5 9 13.5Z" fill="#ECA72C" />
			</svg>

		</SvgIcon>
	);
}

export default WarningIcon;
