import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import theme from '../../../theme';
import { share, resetShare } from '../../../features/filters/shareSlice';
import SuccessMessage from '../../SuccessMessage';
import ErrorMessage from '../../DisplayErrorMessage/ErrorMessage';
import SharedLinkCard from '../../Cards/SharedLinkCard';

function ShareButton() {
	const [open, setOpen] = useState(false);

	const clickShare = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box sx={{
			padding: '0px',
		}}
		>
			<LoadingButton
				fullWidth
				variant="outlined"
				sx={{
					mt: 2,
					mb: 2,
					borderRadius: {
						xs: 40,
						sm: 40,
						md: 52,
					},
					height: {
						xs: 26,
						sm: 28,
						md: 30,
					},
					fontSize: {
						xs: 12,
					},
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
					'&:hover': {
						backgroundColor: theme.palette.primary.light,
					},
				}}
				startIcon={<ShareOutlinedIcon />}
				loading={false}
				onClick={clickShare}
			>
				Share
			</LoadingButton>
			<SharedLinkCard open={open} handleClose={handleClose} />
		</Box>
	);
}

export default ShareButton;
