import { createAsyncThunk } from '@reduxjs/toolkit';
import { login as loginApi } from '../../api';
// eslint-disable-next-line consistent-return
const login = createAsyncThunk('auth/login', async (data, thunkAPI) => {
	try {
		console.log('data', data);
		// const response = await loginApi(data.email, data.password, data.rememberMe);
		const response = await loginApi(data);
		// console.log('User logged in successfully and data saved in cookies');
		return {
			isLoggedIn: true,
			user_id: response.user_id,
			productList: response.productList,
		};
	} catch (error) {
		return thunkAPI.rejectWithValue(error.message);
	}
});

export default login;
