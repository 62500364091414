import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../features';
import errorHandlingMiddleware from './errorHandlingMiddleware';

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorHandlingMiddleware),
});

export default store;
