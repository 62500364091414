import axiosInstance from '../utils/axiosInstance';

export const auth = async () => axiosInstance.get('/auth');

export const login = async (loginInfo) => axiosInstance.post('/login', loginInfo);

export const checkOath = async () => axiosInstance.get('/auth');

export const getProductInfo = async (productId) => axiosInstance.get(`/product/${productId}`);

export const getProductFilter = async (productId) => axiosInstance.get(`/product/${productId}/filter`);

export const getProductRating = async (productId) => axiosInstance.get(`/product/${productId}/rating`);

export const getProductFeature = async (productId, featureId) => axiosInstance.get(`/product/${productId}/feature/${featureId}`);

export const getPersona = async () => axiosInstance.get('/persona');

export const getCodesigners = async (productId, queryParams) => {
	if (!queryParams) {
		return axiosInstance.get(`/product/${productId}/codesigner`);
	}
	return axiosInstance.get(`/product/${productId}/codesigner?${queryParams}`);
};
