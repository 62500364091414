/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import login from '../../thunk/loginThunk';
import auth from '../../thunk/authThunk';

const handlePending = (state) => {
	state.loading = true;
	state.error = null;
};

const handleLoginFulfilled = (state, action) => {
	state.loading = false;
	state.isLoggedIn = true;
	state.userId = action.payload.user_id;
	state.error = null;
};

const handleRejected = (state, action) => {
	state.loading = false;
	state.error = action.payload;
	state.isLoggedIn = false;
};

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		isLoggedIn: null,
		userId: null,
		loading: false,
		error: null,
		success: null,
	},
	reducers: {
		logout: (state) => {
			state.isLoggedIn = false;
			state.userId = null;
			state.loading = false;
			state.error = null;
			state.success = null;
		},
		resetProcess: (state) => {
			state.error = null;
			state.success = null;
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, handlePending)
			.addCase(login.fulfilled, handleLoginFulfilled)
			.addCase(login.rejected, handleRejected)
			.addCase(auth.pending, handlePending)
			.addCase(auth.fulfilled, handleLoginFulfilled)
			.addCase(auth.rejected, handleRejected);
	},
});

export const { logout, resetProcess } = authSlice.actions;
export default authSlice.reducer;
