import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCodesigners as getCodesignersAPI } from '../../api';

const getCodesigners = createAsyncThunk('codesigner/getCodesigners', async (data, thunkAPI) => {
	try {
		const { productId, query } = data;
		const response = await getCodesignersAPI(productId, query);
		return response;
	} catch (error) {
		console.error('Error in getCodesigners thunk:', error);
		return thunkAPI.rejectWithValue(error.message || error);
	}
});

export default getCodesigners;
