import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setPersonaQuery } from '../../../features/filters/personaQuery';

function ApplyFilterButton() {
	const dispatch = useDispatch();
	const queryParamString = useSelector((state) => state.filters.persona.queryParamString);
	const { minAge, maxAge } = useSelector((state) => state.filters.age);
	const { male, female } = useSelector((state) => state.filters.gender.selectedGender);
	const currentSelectedProduct = useSelector((state) => state.filters.product.currentProduct);
	const applyEnabled = currentSelectedProduct !== null;

	const handleApply = () => {
		let query = `age_min=${minAge}&age_max=${maxAge}`;
		if (male) {
			query += '&male';
		}
		if (female) {
			query += '&female';
		}
		if (queryParamString) {
			query += `&${queryParamString}`;
		}
		dispatch(setPersonaQuery(query));
	};

	return (
		<Button
			onClick={handleApply}
			style={{
				cursor: 'pointer',
				textTransform: 'none',
				fontFamily: 'Inter, sans-serif',
				padding: '-2px',
			}}
			size="small"
			variant="outlined"
			disabled={!applyEnabled}
		>
			Apply
		</Button>
	);
}

export default ApplyFilterButton;
