/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import getPersona from '../../thunk/personaThunk';
import { RESET_STATE } from '../../constants';
// Turn the hook into a regular function
const buildQueryParamString = (personaFilter) => {
	const personaFilterKeys = Object.keys(personaFilter);
	const selectedPersonaFilterKeys = personaFilterKeys.filter((key) => personaFilter[key].selected);
	const selectedPersonaFilter = selectedPersonaFilterKeys.map((key) => {
		const { min, max, name } = personaFilter[key];
		return `${name}_min=${min}&${name}_max=${max}`;
	})
		.join('&');
	return selectedPersonaFilter;
};

const personaSlice = createSlice({
	name: 'personaFilter',
	initialState: {
		queryParamString: '',
	},

	reducers: {
		// reducer to set persona as selected or not
		setSelectedPersona(state, action) {
			// console.log('setSelectedPersona', action.payload);
			const { id } = action.payload;
			state[id].selected = !state[id].selected;
			state.queryParamString = buildQueryParamString(state);
		},

		// reducer to set min and max values for persona
		setSelectedPersonaRange(state, action) {
			// console.log('setSelectedPersonaRange', action.payload);
			const {
				id, min, max,
			} = action.payload;
			if (state[id].selected) {
				state[id].min = min;
				state[id].max = max;
			}
			// update queryParamString
			state.queryParamString = buildQueryParamString(state);
		},

	},

	extraReducers: (builder) => {
		builder.addCase(getPersona.pending, (state) => {
			// reset to all true, clear all filters, reset to initial state
			state.queryParamString = '';
		});
		builder.addCase(getPersona.fulfilled, (state, action) => {
			const personaList = action.payload.reduce(
				(acc, persona) => {
					acc[persona.id] = {
						selected: true,
						min: persona.isBinary ? 0 : 1,
						max: persona.isBinary ? 1 : 5,
						isBinary: persona.isBinary,
						name: persona.name,
					};
					return acc;
				},
				{},
			);
			// Set the state but reset queryParamString to an empty string
			Object.assign(state, personaList);
			state.queryParamString = ''; // Set to empty string when API call is fulfilled
		});

		builder.addCase(RESET_STATE, (state) => {
			Object.keys(state).forEach((key) => {
				// if key is the persona id, reset selected to true and min and max to default
				if (key !== 'queryParamString') {
					state[key].selected = true;
					// if persona is binary, set min to 0 and max to 1, else set min to 1 and max to 5
					state[key].min = state[key].isBinary ? 0 : 1;
					state[key].max = state[key].isBinary ? 1 : 5;
				}
			});
			// reset queryParamString to empty string
			state.queryParamString = '';
		});
	},
});

export const { setSelectedPersona, setSelectedPersonaRange } = personaSlice.actions;
export default personaSlice.reducer;
